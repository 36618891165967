import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { NewsGrid, NewsWidgetLayout } from '../../components';
import useNews from '../../hooks/news';
import useViewport from '../../hooks/viewport';
import useTranslations from '../../hooks/translations';

import styles from './NewsList.module.css';

const NewsList = ({
    data: {
        contentful: {
            pageNewsListCollection: { items: [page] },
            newsCategoryCollection: { items: allCategories },
        },
    },
    location,
}) => {
    const {
        tabTitle,
        description,
    } = page;

    const translations = useTranslations();
    const { allNews, filteredNews } = useNews();
    const { isDesktop, isLargeDesktop } = useViewport();

    const layoutData = {
        location,
        seoData: {
            title: `${tabTitle} - PA Advogados`,
            description,
        },
    };

    const currentNews = useMemo(() => {
        if (isDesktop || isLargeDesktop) {
            return allNews;
        }

        return filteredNews;
    }, [allNews, filteredNews, isDesktop, isLargeDesktop]);

    return (
        <NewsWidgetLayout
            layout={ layoutData }
            allCategories={ allCategories }>
            <div className={ styles.container }>
                <h1 className={ styles.pageTitle }>
                    { tabTitle }
                </h1>
                <p className={ styles.title }>{ translations.latestNews }</p>
                <NewsGrid
                    news={ currentNews }
                    className={ styles.newsGrid } />
            </div>
        </NewsWidgetLayout>
    );
};

NewsList.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            pageNewsListCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
            newsCategoryCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default NewsList;
